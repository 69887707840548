.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  color: var(--button-primary-fg, #fff);
  font-weight: 600;
  font-family: Inter, sans-serif;
  font-style: normal;
  background: var(--buttons-primary, #7f56d9);
  border: 1px solid var(--buttons-primary, #7f56d9);
  border-radius: var(--radius-md, 8px);
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  cursor: pointer;
  font-optical-sizing: auto;
  font-variation-settings: 'slnt' 0;
}

.button:disabled {
  color: #ffffff9c;
  cursor: not-allowed;
}

.buttonPrimary {
  color: white;
  background-color: hsl(203deg 98% 55%);
}

.buttonSecondary {
  color: hsl(0deg 0% 20%);
  background-color: transparent;
  box-shadow: rgb(0 0 0 / 15%) 0 0 0 1px inset;
}

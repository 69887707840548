.mainContainer {
  display: flex;
  margin-top: 20%;
}

.leftGridContent {
  flex-direction: column;
  align-items: center;
}

.rightGridContent {
  flex-direction: column;
  align-items: center;
}

.error404 {
  color: var(--purple-3);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5%;
}

.header {
  margin-bottom: 5%;
  font-weight: 600;
  font-size: 60px;
}

.errorMessage {
  margin-bottom: 10%;
  font-weight: 300;
  font-size: 20px;
  color: var(--text-tertiary-600);
}

.leftGrid {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.rightGrid {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.buttonsContainer {
  display: flex;
}

.buttonsContainer button {
  font-weight: 500;
  font-size: 18px;
  border-radius: 8px;
  margin-right: 1%;
  padding: 3% 5% 3% 5%;
}

.goBackBtn {
  border: 1px;
  padding: 2%;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-primary);
  cursor: pointer;
  color: var(--text-secondary-700);
}

.goBackBtn svg {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}
